import { Component, OnInit } from '@angular/core';
import { Constant } from 'src/app/helper/constant';
import { MatDialog } from '@angular/material/dialog';
import { Login } from 'src/app/model/authentication/login';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { LogoutComponent } from 'src/app/pages/authentication/logout/logout.component';
import { InformationComponent } from 'src/app/pages/authentication/information/information.component';

@Component({
  selector: 'fury-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  styleUrls: ['./toolbar-user.component.scss'],
})
export class ToolbarUserComponent implements OnInit {
  isOpen: boolean;
  userInformation: Login;
  imagePath: string;

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit() {
    this.userInformation = this.authenticationService.getUserInformation();

    this.validateImage();
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
    this.isOpen = false;
  }

  validateImage() {
    this.imagePath = !this.userInformation.sex
      ? Constant.GenericImageWoman
      : Constant.GenericImageMan;
  }

  information() {
    this.dialog.open(InformationComponent);
  }

  logout() {
    this.dialog
      .open(LogoutComponent, {})
      .afterClosed()
      .subscribe((answer) => {
        if (answer === Constant.UserResponseAfirmative) {
          const tokenIsValid = this.authenticationService.tokenIsValid();

          if (tokenIsValid) {
            const token = this.authenticationService.getToken();

            this.logoutService(token).then();
          } else {
            return;
          }
        }
      });
  }

  private async logoutService(token: string) {
    await this.authenticationService.logout(token).toPromise();
  }
}
