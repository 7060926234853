<div
  class="background-pattern page"
  fxLayout="column"
  fxLayoutAlign="center center"
>
  <div [@fadeInUp] class="card border-radius elevation">
    <div
      class="header padding background-primary"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <div class="logo fill-primary-contrast">
        <svg viewBox="0 0 33.74 33.99" xmlns="http://www.w3.org/2000/svg">
          <title>icon</title>
          <path
            d="M26.83,19.33c-.36.37-.73.71-1.09,1l.31,3.22a5.41,5.41,0,0,1-2.43,5.14L15.72,34l-.67-8.74a6.54,6.54,0,0,1-6.57-6.53L0,18.18l5-7.93a5.41,5.41,0,0,1,5.23-2.54l3.1.31q.49-.55,1.05-1.11C20.78.54,30.82-.62,33.48.26,34.37,2.93,33.2,13,26.83,19.33Zm-.45-12a3.77,3.77,0,1,0,0,5.33A3.77,3.77,0,0,0,26.38,7.36ZM2.82,23.72l3.24-3.24a8.24,8.24,0,0,0,7.11,7.1L10.5,30.25,7.74,33V29.46L2.41,31.23l1.78-5.32H.64Z"
          ></path>
        </svg>
      </div>
    </div>
    <div class="padding" fxLayout="column" [formGroup]="form">
      <div fxFlex="auto" fxLayout="column">
        <mat-form-field fxFlex="grow">
          <mat-label>E-Mail</mat-label>
          <input matInput required formControlName="email" />
          <mat-hint>Enter your mail to recover your password.</mat-hint>
          <mat-error *ngIf="form.get('email').hasError('required')">
            We can't recover your password, without your email.
          </mat-error>
        </mat-form-field>
      </div>

      <button
        type="button"
        color="primary"
        mat-raised-button
        (click)="onSubmit()"
      >
        SEND RECOVERY LINK
      </button>
    </div>
  </div>
</div>
