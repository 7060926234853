<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
  <div>Información de Usuario</div>
</div>

<form [formGroup]="form">
  <mat-dialog-content>
    <div class="person">
      <div fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field fxFlex>
          <mat-label>Nombre Completo</mat-label>
          <input
            [readonly]="true"
            type="text"
            formControlName="completeName"
            matInput
          />
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field fxFlex>
          <mat-label>Edad</mat-label>
          <input [readonly]="true" type="text" formControlName="age" matInput />
        </mat-form-field>

        <mat-form-field fxFlex>
          <mat-label>Documento</mat-label>
          <input
            [readonly]="true"
            type="text"
            formControlName="document"
            matInput
          />
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row">
      <mat-form-field fxFlex>
        <mat-label>Roles</mat-label>
        <input [readonly]="true" type="text" formControlName="roles" matInput />
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row">
      <mat-form-field fxFlex>
        <mat-label>Email</mat-label>
        <input [readonly]="true" type="text" formControlName="email" matInput />
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayout.gt-sm="row">
      <mat-form-field fxFlex>
        <mat-label>Mobil</mat-label>
        <input
          [readonly]="true"
          type="text"
          formControlName="mobile"
          matInput
        />
      </mat-form-field>
    </div>
  </mat-dialog-content>
</form>
