import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'fury-logout',
  templateUrl: './logout.component.html',
})
export class LogoutComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<LogoutComponent>) {}

  ngOnInit(): void {}

  logout(answer: string) {
    this.dialogRef.close(answer);
  }
}
