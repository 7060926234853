import { Enums } from './helper/enums';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { CustomFunction } from './helper/custom-function';
import { ActivatedRoute } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { MatIconRegistry } from '@angular/material/icon';
import { ThemeService } from 'src/@fury/services/theme.service';
import { SidenavService } from './layout/sidenav/sidenav.service';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { SplashScreenService } from '../@fury/services/splash-screen.service';

@Component({
  selector: 'fury-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private iconRegistry: MatIconRegistry,
    private sidenavService: SidenavService,
    @Inject(DOCUMENT) private document: Document,
    private splashScreenService: SplashScreenService,
  ) {}

  ngOnInit() {
    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has('style')))
      .subscribe((queryParamMap) =>
        this.themeService.setStyle(queryParamMap.get('style')),
      );

    this.iconRegistry.setDefaultFontSetClass('material-icons-outlined');

    this.themeService.theme$.subscribe((theme) => {
      if (theme[0]) {
        this.renderer.removeClass(this.document.body, theme[0]);
      }

      this.renderer.addClass(this.document.body, theme[1]);
    });

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    const sidenavInformation = CustomFunction.getStorageItem(
      Enums.StorageVariables.SIDENAV_INFORMATION,
    );

    if (sidenavInformation) {
      this.sidenavService.items =
        CustomFunction.deserialize(sidenavInformation);

      return;
    }

    this.sidenavService.addItems(CustomFunction.routesDefined());
  }
}
