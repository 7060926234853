import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import localeEsPe from '@angular/common/locales/es-PE';
import { PageModule } from './shared/page/page.module';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { TitleModule } from './shared/title/title.module';
import { MatButtonModule } from '@angular/material/button';
import { FuryCardModule } from './shared/card/card.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TruncatePipe } from 'src/app/core/pipe/truncate-pipe';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { DateFormatPipe } from 'src/app/core/pipe/date-format-pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CustomMatPaginatorIntl } from './shared/custom-mat-paginator';
import { PageLayoutModule } from './shared/page-layout/page-layout.module';
import { BreadcrumbsModule } from './shared/breadcrumbs/breadcrumbs.module';
import { ModalValidateModule } from 'src/app/layout/modal-validate/modal-validate.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HighlightModule } from './shared/highlightjs/highlight.module';

registerLocaleData(localeEsPe);

@NgModule({
  imports: [CommonModule],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }],
  declarations: [TruncatePipe, DateFormatPipe],
  exports: [
    BreadcrumbsModule,
    TitleModule,
    PageModule,
    SidebarModule,
    RouterModule,
    PageLayoutModule,
    FuryCardModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    TruncatePipe,
    DateFormatPipe,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatMenuModule,
    FontAwesomeModule,
    ScrollingModule,
    ModalValidateModule,
    HighlightModule,
  ],
})
export class FurySharedModule {}
