import { Enums } from 'src/app/helper/enums';

export class GenericOption {
  id: number;
  name: string;
  description: string;
  active: boolean;
  order: number;
  color: string;
  fontWeight: string;
  idLevel: number;
  pensionAmount: number;
  configurationVariableType: Enums.ConfigurationVariableType;
  value: any;
}
