import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Enums } from 'src/app/helper/enums';
import {
  BankType,
  CommitmentStatus,
  ContractType,
  Department,
  Grade,
  GradeInstruction,
  InformationStage,
  Level,
  MoneyMovementType,
  PaymentType,
  PeriodicityType,
  Permission,
  PersonType,
  PositionType,
  Reference,
  Relationship,
  Role,
  Section,
  Turn,
} from 'src/app/model/master-data/master-data';
import { HttpClient } from '@angular/common/http';
import { MediaOption } from 'src/app/model/layout/media';
import { Province } from 'src/app/model/person/province';
import { District } from 'src/app/model/person/district';
import { Document } from 'src/app/model/person/document';
import { environment } from 'src/environments/environment';
import { WorkerAssignment } from 'src/app/model/person/worker';
import { CustomFunction } from 'src/app/helper/custom-function';
import { GenericOption } from 'src/app/model/base/generic-option';
import { GradeEquivalence } from 'src/app/model/master-data/grade-equivalence';
import { UnityConfiguration } from 'src/app/model/master-data/unity-configuration';
import { UnityConfigurationShow } from 'src/app/model/master-data/unity-configuration-show';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  constructor(protected httpClient: HttpClient) {}

  getPersonTypes(): Observable<PersonType[]> {
    return this.httpClient.get<PersonType[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/person-type`,
    );
  }

  getDocumentTypes(): Observable<Document[]> {
    return this.httpClient.get<Document[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/document-type`,
    );
  }

  getDepartments(): Observable<Department[]> {
    return this.httpClient.get<Department[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/department`,
    );
  }

  getProvinces(id: number): Observable<Province[]> {
    return this.httpClient.get<Province[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/province/${id}`,
    );
  }

  getDistricts(id: number): Observable<District[]> {
    return this.httpClient.get<District[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/district/${id}`,
    );
  }

  getRoles(): Observable<Role[]> {
    return this.httpClient.get<Role[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/role`,
    );
  }

  getGradeInstructions(): Observable<GradeInstruction[]> {
    return this.httpClient.get<GradeInstruction[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/grade-instruction`,
    );
  }

  getRelationshipTypes(): Observable<Relationship[]> {
    return this.httpClient.get<Relationship[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/relationship-type`,
    );
  }

  getReferenceTypes(): Observable<Reference[]> {
    return this.httpClient.get<Reference[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/reference-type`,
    );
  }

  getPermissions(id: number): Observable<Permission[]> {
    return this.httpClient.get<Permission[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/permission/${id}`,
    );
  }

  getTurns(): Observable<Turn[]> {
    return this.httpClient.get<Turn[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/turn-type`,
    );
  }

  getGrades(idLevel: number): Observable<Grade[]> {
    return this.httpClient.get<Grade[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/grade-type/${idLevel}`,
    );
  }

  getLevels(): Observable<Level[]> {
    return this.httpClient.get<Level[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/level-type`,
    );
  }

  getLevelTypeWithPensions(year: number): Observable<Level[]> {
    return this.httpClient.get<Level[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/level-type/${year}/pension`,
    );
  }

  getSections(): Observable<Section[]> {
    return this.httpClient.get<Section[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/section-type`,
    );
  }

  getPeriodicityTypes(): Observable<PeriodicityType[]> {
    return this.httpClient.get<PeriodicityType[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/periodicity-type`,
    );
  }

  getContractTypes(): Observable<ContractType[]> {
    return this.httpClient.get<ContractType[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/contract-type`,
    );
  }

  getPositionTypes(): Observable<PositionType[]> {
    return this.httpClient.get<PositionType[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/position-type`,
    );
  }

  getDiscountTypes(): Observable<MoneyMovementType[]> {
    return this.httpClient.get<MoneyMovementType[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/discount-type`,
    );
  }

  getPaymentMethods(): Observable<PaymentType[]> {
    return this.httpClient.get<PaymentType[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/payment-type`,
    );
  }

  getIncomePaymentTypes(): Observable<MoneyMovementType[]> {
    return this.httpClient.get<MoneyMovementType[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/income-payment-type`,
    );
  }

  getBankTypes(): Observable<BankType[]> {
    return this.httpClient.get<BankType[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/bank-type`,
    );
  }

  getUnityConfigurations(): Observable<UnityConfigurationShow[]> {
    return this.httpClient.get<UnityConfigurationShow[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/unity`,
    );
  }

  getUnityConfigurationByYear(year: number): Observable<UnityConfiguration[]> {
    return this.httpClient.get<UnityConfiguration[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/unity/${year}`,
    );
  }

  updateUnityConfiguration(
    unityConfigurations: UnityConfiguration[],
  ): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/unity`,
      CustomFunction.serialize(unityConfigurations),
    );
  }

  addUnityConfiguration(
    unityConfigurations: UnityConfiguration[],
  ): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/unity`,
      CustomFunction.serialize(unityConfigurations),
    );
  }

  getPeriodsByYear(year: number): Observable<number[]> {
    return this.httpClient.get<number[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/bimester/${year}`,
    );
  }

  getPeriodListByYear(
    year: number,
    idStudent: number,
  ): Observable<GenericOption[]> {
    return this.httpClient.get<GenericOption[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/period/${year}?idStudent=${idStudent}`,
    );
  }

  getInformStages(): Observable<InformationStage[]> {
    return this.httpClient.get<InformationStage[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/information/stage`,
    );
  }

  getActiveYears(): Observable<GenericOption[]> {
    return this.httpClient.get<GenericOption[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/active/years`,
    );
  }

  getWorkshopResultStates(): Observable<GenericOption[]> {
    return this.httpClient.get<GenericOption[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/workshop/result/states`,
    );
  }

  getEvaluationStates(): Observable<GenericOption[]> {
    return this.httpClient.get<GenericOption[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/evaluation/states`,
    );
  }

  getAssignmentsByTeacher(
    idWorker: number,
    year: number,
  ): Observable<WorkerAssignment> {
    return this.httpClient.get<WorkerAssignment>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/${idWorker}/level?year=${year}`,
    );
  }

  getAllMediaOptionsById(idMedia: number): Observable<MediaOption[]> {
    return this.httpClient.get<MediaOption[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/media-option/${idMedia}`,
    );
  }

  addMediaForEnrollment(enrollmentId: number): Observable<number> {
    // @ts-ignore
    return this.httpClient.post<number>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/enrollment/${enrollmentId}/media`,
    );
  }

  addMediaForPsychologyAppointment(
    psychologyAppointmentId: number,
  ): Observable<number> {
    // @ts-ignore
    return this.httpClient.post<number>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/psychology-appointment/${psychologyAppointmentId}/media`,
    );
  }

  addMediaOptions(medias: MediaOption[]): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/media-option`,
      CustomFunction.serialize(medias),
    );
  }

  updateMediaOptions(medias: MediaOption[]): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/media-option`,
      CustomFunction.serialize(medias),
    );
  }

  deleteMediaOption(id: number): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/media-option/${id}`,
    );
  }

  getMediaTypes(): Observable<GenericOption[]> {
    return this.httpClient.get<GenericOption[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/media-types`,
    );
  }

  getCommitmentStatus(): Observable<CommitmentStatus[]> {
    return this.httpClient.get<CommitmentStatus[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/commitment`,
    );
  }

  getGradeEquivalences = (
    year: number,
    levelId: number,
  ): Observable<GradeEquivalence[]> =>
    this.httpClient.get<GradeEquivalence[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/${year}/grade/equivalence/${levelId}/level`,
    );

  getAssistanceJustificationType = (
    isForWorker: boolean,
  ): Observable<GenericOption[]> =>
    this.httpClient.get<GenericOption[]>(
      `${environment.backend}/${Enums.ApiPath.MASTER}/assistance/justification-type?isForWorker=${isForWorker}`,
    );
}
