<div class="toolbar-user" (furyClickOutside)="onClickOutside()" fxFlex="grow">
  <button
    class="button"
    mat-button
    (click)="toggleDropdown()"
    [class.open]="isOpen"
    fxFlex="grow"
  >
    <span fxLayout="row" fxLayoutAlign="start center">
      <img class="avatar" src="{{ imagePath }}" />
      <span class="name" fxHide fxShow.gt-sm>{{
        userInformation.completeName
      }}</span>
      <mat-icon class="icon" fxHide fxShow.gt-sm>keyboard_arrow_down</mat-icon>
    </span>
  </button>

  <div class="dropdown" [class.open]="isOpen">
    <div class="content">
      <div class="list">
        <div class="list-item" matRipple (click)="information()">
          <mat-icon class="list-item-icon">account_circle</mat-icon>
          <span>Perfil</span>
        </div>

        <div class="list-item" matRipple>
          <mat-icon class="list-item-icon">help</mat-icon>
          <span>Ayuda</span>
        </div>

        <mat-divider></mat-divider>

        <div class="list-item" matRipple (click)="logout()">
          <mat-icon class="list-item-icon">exit_to_app</mat-icon>
          <span>Cerrar Sesión</span>
        </div>
      </div>
    </div>
  </div>
</div>
