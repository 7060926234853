import { Constant } from './constant';
import * as CryptoJS from 'crypto-js';
import { Enums } from 'src/app/helper/enums';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { GenericOption } from 'src/app/model/base/generic-option';
import { ListColumn } from 'src/@fury/shared/list/list-column.model';
import { SidenavItem } from '../model/layout/sidenav-item.interface';
import { FileUploadModel } from 'src/app/model/layout/file-upload-model';
import { GradeEquivalence } from 'src/app/model/master-data/grade-equivalence';

// tslint:disable-next-line: no-namespace
export namespace CustomFunction {
  export const currentTimeStampFormatted = () => {
    const date = new Date();

    return `${date.getUTCDate()}/${
      date.getUTCMonth() + 1
    }/${date.getUTCFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  };

  export function distinct<T>(array: T[]): T[] {
    return array.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }

  export const currentTimeStampFirebase = () => {
    const date = new Date();

    return `${date.getUTCDate()}-${
      date.getUTCMonth() + 1
    }-${date.getUTCFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  };

  export const pathFromDate = (date: Date) => {
    const inputDate = new Date(date);

    return `${inputDate.getUTCDate()}-${
      inputDate.getUTCMonth() + 1
    }-${inputDate.getUTCFullYear()} ${inputDate.getHours()}:${inputDate.getMinutes()}:${inputDate.getSeconds()}`;
  };

  export const timeStampToStringFormatted = () => {
    const date = new Date();

    return `${date.getUTCDate()}-${
      date.getUTCMonth() + 1
    }-${date.getUTCFullYear()}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;
  };

  export const getCurrentTimeStamp = (): any => {
    const now = new Date();
    now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

    return now.toISOString().slice(0, 16);
  };

  export const getCurrentTimeStampAsString = (): string =>
    `${CustomFunction.getCurrentTimeStamp()}`;

  export const getSplitStringFromList = (
    list: GenericOption[],
    separator: string,
  ): string => {
    const newList: string[] = [];

    list.forEach((element: GenericOption) => {
      newList.push(element.name);
    });

    return newList.join(separator);
  };

  export const getPresentationName = (
    value: string,
    sliceNumber: number,
  ): string => `${value.slice(0, sliceNumber)}...`;

  export const getCurrentYear = () => new Date().getFullYear();

  export const getCurrentMonth = () => new Date().getMonth();

  export const getMonths = () => [
    Enums.Months.Enero,
    Enums.Months.Febrero,
    Enums.Months.Marzo,
    Enums.Months.Abril,
    Enums.Months.Mayo,
    Enums.Months.Junio,
    Enums.Months.Julio,
    Enums.Months.Agosto,
    Enums.Months.Setiembre,
    Enums.Months.Octubre,
    Enums.Months.Noviembre,
    Enums.Months.Diciembre,
  ];

  export const findMonthName = (monthId: number): string => {
    return getMonthsV2().find((x) => x.id === monthId).name;
  };

  export const getMonthsV2 = (): GenericOption[] => {
    let monthOptions: GenericOption[] = [];

    monthOptions[0] = new GenericOption();
    monthOptions[0].id = 1;
    monthOptions[0].name = 'ENERO';

    monthOptions[1] = new GenericOption();
    monthOptions[1].id = 2;
    monthOptions[1].name = 'FEBRERO';

    monthOptions[2] = new GenericOption();
    monthOptions[2].id = 3;
    monthOptions[2].name = 'MARZO';

    monthOptions[3] = new GenericOption();
    monthOptions[3].id = 4;
    monthOptions[3].name = 'ABRIL';

    monthOptions[4] = new GenericOption();
    monthOptions[4].id = 5;
    monthOptions[4].name = 'MAYO';

    monthOptions[5] = new GenericOption();
    monthOptions[5].id = 6;
    monthOptions[5].name = 'JUNIO';

    monthOptions[6] = new GenericOption();
    monthOptions[6].id = 7;
    monthOptions[6].name = 'JULIO';

    monthOptions[7] = new GenericOption();
    monthOptions[7].id = 8;
    monthOptions[7].name = 'AGOSTO';

    monthOptions[8] = new GenericOption();
    monthOptions[8].id = 9;
    monthOptions[8].name = 'SETIEMBRE';

    monthOptions[9] = new GenericOption();
    monthOptions[9].id = 10;
    monthOptions[9].name = 'OCTUBRE';

    monthOptions[10] = new GenericOption();
    monthOptions[10].id = 11;
    monthOptions[10].name = 'NOVIEMBRE';

    monthOptions[11] = new GenericOption();
    monthOptions[11].id = 12;
    monthOptions[11].name = 'DICIEMBRE';

    return monthOptions;
  };

  export const getMoneyMovements = (): GenericOption[] => {
    let moneyMovementOptions: GenericOption[] = [];

    moneyMovementOptions[0] = new GenericOption();
    moneyMovementOptions[0].id = 1;
    moneyMovementOptions[0].name = 'PENSIONES';

    moneyMovementOptions[1] = new GenericOption();
    moneyMovementOptions[1].id = 3;
    moneyMovementOptions[1].name = 'MORAS';

    moneyMovementOptions[2] = new GenericOption();
    moneyMovementOptions[2].id = 4;
    moneyMovementOptions[2].name = 'MATERIAL EDUCATIVO';

    moneyMovementOptions[3] = new GenericOption();
    moneyMovementOptions[3].id = 5;
    moneyMovementOptions[3].name = 'UNIFORMES';

    moneyMovementOptions[4] = new GenericOption();
    moneyMovementOptions[4].id = 6;
    moneyMovementOptions[4].name = 'MATRICULAS';

    moneyMovementOptions[5] = new GenericOption();
    moneyMovementOptions[5].id = 7;
    moneyMovementOptions[5].name = 'DESCUENTOS TARDANZAS';

    moneyMovementOptions[6] = new GenericOption();
    moneyMovementOptions[6].id = 8;
    moneyMovementOptions[6].name = 'DESCUENTOS FALTAS';

    moneyMovementOptions[7] = new GenericOption();
    moneyMovementOptions[7].id = 10;
    moneyMovementOptions[7].name = 'OTROS';

    return moneyMovementOptions;
  };

  export const getPensionApportationType = (): GenericOption[] => {
    const informations: GenericOption[] = [];

    informations[0] = new GenericOption();
    informations[0].id = 1;
    informations[0].name = 'AFP';

    informations[1] = new GenericOption();
    informations[1].id = 2;
    informations[1].name = 'ONP';

    return informations;
  };

  export const getMonthValue = (month: string) => {
    switch (month) {
      case Enums.Months.Enero:
        return 1;
      case Enums.Months.Febrero:
        return 2;
      case Enums.Months.Marzo:
        return 3;
      case Enums.Months.Abril:
        return 4;
      case Enums.Months.Mayo:
        return 5;
      case Enums.Months.Junio:
        return 6;
      case Enums.Months.Julio:
        return 7;
      case Enums.Months.Agosto:
        return 8;
      case Enums.Months.Setiembre:
        return 9;
      case Enums.Months.Octubre:
        return 10;
      case Enums.Months.Noviembre:
        return 11;
      case Enums.Months.Diciembre:
        return 12;
    }
  };

  export const getDays = () => [
    'domingo',
    'lunes',
    'martes',
    'miercoles',
    'jueves',
    'viernes',
    'sabado',
  ];

  export const createDateFromString = (date: string) => {
    const parts = date.split(Constant.DateSplitSymbol);

    return new Date(+parts[2], +parts[1], +parts[0]);
  };

  export const textOnly = (key: string): boolean =>
    new RegExp(/^[A-Za-z0-9 ,.\u00C0-\u017F]+$/).test(key);

  export const numberOnly = (key: string) =>
    new RegExp(Constant.RegexNumber).test(key);

  export const decimalOnly = (key: string) =>
    new RegExp(Constant.RegexDecimalNumber).test(key);

  export const validYear = (key: string) =>
    new RegExp(Constant.RegexValidateYear).test(key);

  export const validDocumentDni = (key: string) =>
    new RegExp(Constant.RegexValidateDni).test(key);

  export const validDocumentForeignCard = (key: string) =>
    new RegExp(Constant.RegexValidateForeignCard).test(key);

  export const validDocumentRuc = (key: string) =>
    new RegExp(Constant.RegexValidateRuc).test(key);

  export const validateDocumentFormat = (
    documentType: number,
    document: string,
  ) => {
    let result = Constant.True;

    switch (documentType) {
      case Constant.DocumentTypeDni:
        result = CustomFunction.validDocumentDni(document);
        break;

      case Constant.DocumentTypeRuc:
        result = CustomFunction.validDocumentRuc(document);
        break;

      default:
        break;
    }

    return result;
  };

  export const getSexOptions = () => [
    Constant.ManSexOption,
    Constant.WomanSexOption,
  ];

  export const dateTimeValidation = (key: string) =>
    new RegExp(Constant.RegexNumber).test(key) ||
    new RegExp(Constant.RegexSlash).test(key);

  export const moneyOnly = (key: string, value: string) => {
    const numberRegex = new RegExp(Constant.RegexNumber);
    const dot = Constant.Dot;

    if (value.includes(Constant.Dot) && key === Constant.Dot) {
      return false;
    }

    return numberRegex.test(key) || dot === key;
  };

  export const appendNumberToStringList = (
    stringList: string,
    newNumber: string,
  ) => {
    const numbers = stringList.split(',');

    if (numbers.indexOf(newNumber) === -1) {
      numbers.push(newNumber);
    } else {
      const index = numbers.indexOf(newNumber);
      numbers.splice(index, 1);
    }

    if (stringList === '') {
      return newNumber;
    }

    return numbers.join(',');
  };

  export const convertStringToList = (stringList: string) =>
    stringList.split(',');

  export const convertStringToIntList = (stringList: string) =>
    stringList.split(',').map(Number);

  export const convertIntArrayToStringCommaSeparated = (numbers: number[]) =>
    numbers.join(',');

  export const convertStringArrayToStringCommaSeparated = (strings: string[]) =>
    strings.join(',');

  export const convertIntArrayToStringPercentageSeparated = (
    numbers: number[],
  ) => numbers.join('%');

  export const dateToStringFormatted = (date: Date) => {
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  export const dateTimeToStringFormatted = (dateAsString: string) => {
    const date: Date = new Date(dateAsString);

    return `${date.getUTCDate()}/${
      date.getUTCMonth() + 1
    }/${date.getUTCFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  };

  export const getVisibleColumns = (columns: ListColumn[]) =>
    columns.filter((column) => column.visible).map((column) => column.property);

  export const deleteRowFromTable = (
    row: any,
    dataSource: MatTableDataSource<any>,
  ) => {
    const index = dataSource.data.indexOf(row);
    dataSource.data.splice(index, 1);
    dataSource._updateChangeSubscription();
  };

  export const onFilterChange = (
    value: string,
    dataSource: MatTableDataSource<any>,
  ) => {
    if (!dataSource) {
      return;
    }

    dataSource.filter = value.trim().toLowerCase();
  };

  export const viewPaymentOptions = () => {
    const options: GenericOption[] = [];

    const dateOption = new GenericOption();
    dateOption.id = 1;
    dateOption.name = 'Rango de Fechas';

    options.push(dateOption);

    const filterOption = new GenericOption();
    filterOption.id = 2;
    filterOption.name = 'Grados y Nivel';

    options.push(filterOption);

    return options;
  };

  export const routesDefined = (): SidenavItem[] => {
    let sideNavItem: SidenavItem[] = [];

    sideNavItem = [
      {
        name: 'Dashboard',
        routeOrFunction: `/${Enums.SitePath.DASHBOARD}`,
        icon: 'dashboard',
        position: 0,
      },
      {
        name: 'Reportes',
        routeOrFunction: `/${Enums.SitePath.REPORT}`,
        icon: 'admin_panel_settings',
        position: 1,
      },
      {
        name: 'MANEJO ALUMNADO',
        position: 5,
        type: 'subheading',
        customClass: 'first-subheading',
      },
      {
        name: 'Alumnos',
        icon: 'person',
        position: 10,
        subItems: [
          {
            name: 'Listado de Apoderados',
            routeOrFunction: `/${Enums.SitePath.STUDENT_ATTORNEY}`,
            position: 10,
          },
          {
            name: 'Listado de Alumnos',
            routeOrFunction: `/${Enums.SitePath.STUDENT_LIST}`,
            position: 15,
          },
          {
            name: 'Registrar Alumno',
            routeOrFunction: `/${Enums.SitePath.STUDENT_REGISTER}`,
            position: 20,
          },
        ],
      },
      {
        name: 'Matrículas',
        routeOrFunction: `/${Enums.SitePath.ENROLLMENT}`,
        icon: 'web',
        position: 15,
      },
      {
        name: 'Tutoria',
        routeOrFunction: `/${Enums.SitePath.TUTORSHIP}`,
        icon: 'person_search',
        position: 20,
      },
      {
        name: 'Asistencia',
        icon: 'assistant',
        position: 25,
        subItems: [
          {
            name: 'Listado de Asistencia',
            routeOrFunction: `/${Enums.SitePath.ASSISTANT_LIST}`,
            position: 10,
          },
          {
            name: 'Justificar',
            routeOrFunction: `/${Enums.SitePath.ASSISTANT_JUSTIFY}`,
            position: 15,
          },
        ],
      },
      {
        name: 'Observaciones',
        icon: 'assignment_late',
        position: 30,
        subItems: [
          {
            name: 'Listado de Observaciones',
            routeOrFunction: `/${Enums.SitePath.OBSERVATION_LIST}`,
            position: 10,
          },
          {
            name: 'Registrar Observaciones',
            routeOrFunction: `/${Enums.SitePath.OBSERVATION_REGISTER}`,
            position: 15,
          },
        ],
      },
      {
        name: 'Informes',
        routeOrFunction: `/${Enums.SitePath.INFORM}`,
        icon: 'record_voice_over',
        position: 33,
      },
      {
        name: 'Psicologia',
        routeOrFunction: `/${Enums.SitePath.PSYCHOLOGY}`,
        icon: 'tips_and_updates',
        position: 34,
      },
      {
        name: 'MANEJO ACADÉMICO',
        position: 35,
        type: 'subheading',
        customClass: 'first-subheading',
      },
      {
        name: 'Cursos',
        position: 40,
        routeOrFunction: `/${Enums.SitePath.COURSE}`,
        icon: 'menu_book',
      },
      {
        name: 'Competencias',
        position: 45,
        routeOrFunction: `/${Enums.SitePath.COMPETENCE}`,
        icon: 'post_add',
      },
      {
        name: 'Asignatura',
        position: 50,
        routeOrFunction: `/${Enums.SitePath.SUBJECT}`,
        icon: 'assignment',
      },
      {
        name: 'Configurar Competencias',
        position: 52,
        routeOrFunction: `/${Enums.SitePath.ASSIGNED_COMPETENCE}`,
        icon: 'post_add',
      },
      {
        name: 'Estudiantes',
        position: 55,
        routeOrFunction: `/${Enums.SitePath.STUDENT_GRADE}`,
        icon: 'font_download',
      },
      {
        name: 'Talleres',
        position: 57,
        routeOrFunction: `/${Enums.SitePath.WORKSHOP}`,
        icon: 'backup_table',
      },
      {
        name: 'MANEJO PAGOS',
        position: 60,
        type: 'subheading',
        customClass: 'first-subheading',
      },
      {
        name: 'Compromisos de Pago',
        routeOrFunction: `/${Enums.SitePath.PAYMENT_COMMITMENT}`,
        icon: 'history_toggle_off',
        position: 61,
      },
      {
        name: 'Revisiones Bancarias',
        routeOrFunction: `/${Enums.SitePath.BANK_REVISION}`,
        icon: 'query_stats',
        position: 62,
      },
      {
        name: 'Buscar Numero Operación',
        routeOrFunction: `/${Enums.SitePath.FIND_OPERATION_NUMBER}`,
        icon: 'find_in_page',
        position: 65,
      },
      {
        name: 'Pagos Personal',
        position: 75,
        icon: 'how_to_reg',
        subItems: [
          {
            name: 'Pagos',
            routeOrFunction: `/${Enums.SitePath.PERSONAL_PAYMENT}`,
            position: 10,
          },
          {
            name: 'Descuentos Personal',
            routeOrFunction: `/${Enums.SitePath.PERSONAL_DISCOUNT}`,
            position: 15,
          },
          {
            name: 'Adelantos Personal',
            routeOrFunction: `/${Enums.SitePath.PERSONAL_ADVANCE}`,
            position: 20,
          },
        ],
      },
      {
        name: 'Pagos Alumnos',
        position: 80,
        icon: 'perm_identity',
        subItems: [
          {
            name: 'Pagos Estudiantes',
            routeOrFunction: `/${Enums.SitePath.PAYMENT_LIST_STUDENT}`,
            position: 10,
          },
          {
            name: 'Promociones Alumnos',
            routeOrFunction: `/${Enums.SitePath.STUDENT_PROMOTION}`,
            position: 15,
          },
        ],
      },
      {
        name: 'MANEJO PERSONAL',
        position: 85,
        type: 'subheading',
        customClass: 'first-subheading',
      },
      {
        name: 'Personal',
        icon: 'work',
        position: 90,
        subItems: [
          {
            name: 'Listado de Personal',
            routeOrFunction: `/${Enums.SitePath.WORKER_LIST}`,
            position: 10,
          },
          {
            name: 'Registrar Personal',
            routeOrFunction: `/${Enums.SitePath.WORKER_REGISTER}`,
            position: 15,
          },
        ],
      },
      {
        name: 'Contratos',
        routeOrFunction: `/${Enums.SitePath.PAYMENT_PLAN}`,
        icon: 'account_balance_wallet',
        position: 90.1,
      },
      {
        name: 'Evaluaciones',
        routeOrFunction: `/${Enums.SitePath.WORKER_EVALUATION}`,
        icon: 'check_circle',
        position: 91,
      },
      {
        name: 'CONFIGURACION',
        position: 95,
        type: 'subheading',
        customClass: 'first-subheading',
      },
      {
        name: 'Usuarios',
        routeOrFunction: `/${Enums.SitePath.USER_MANAGEMENT}`,
        icon: 'manage_accounts',
        position: 97,
      },
      {
        name: 'Equivalencia Competencias',
        position: 100,
        routeOrFunction: `/${Enums.SitePath.CONFIGURATION_COMPETENCE}`,
        icon: 'chrome_reader_mode',
      },
      {
        name: 'Configuración Pagos Estudiantes',
        position: 105,
        routeOrFunction: `/${Enums.SitePath.CONFIGURATION_STUDENT_PAYMENT}`,
        icon: 'money',
      },
      {
        name: 'Promociones Estudiantes',
        position: 110,
        routeOrFunction: `/${Enums.SitePath.CONFIGURATION_PROMOTION}`,
        icon: 'surround_sound',
      },
      {
        name: 'Creacion Unidades',
        position: 115,
        routeOrFunction: `/${Enums.SitePath.CONFIGURATION_UNITY}`,
        icon: 'event_available',
      },
      {
        name: 'Creacion Areas',
        position: 120,
        routeOrFunction: `/${Enums.SitePath.CONFIGURATION_AREA}`,
        icon: 'group_work',
      },
    ];

    return sideNavItem;
  };

  export const onFileChange = (
    fileUpload: HTMLInputElement,
    firebasePath: string,
    type: number,
    files: FileUploadModel[],
  ) => {
    for (const file of Array.from(fileUpload.files)) {
      files.push({
        data: file,
        state: Constant.FileUploadState,
        inProgress: Constant.True,
        progress: Constant.ZeroValue,
        canRetry: Constant.False,
        canCancel: Constant.True,
      });
    }

    return {
      fileUpload,
      files,
      firebasePath,
      type,
      context: null,
    };
  };

  export const uploadFiles = ({
    fileUpload,
    files,
    firebasePath,
    type,
    context,
  }) => {
    fileUpload.value = '';

    files.forEach((file: FileUploadModel) => {
      CustomFunction.uploadFile(file, firebasePath, type, context);
    });
  };

  export const uploadFile = (
    file: FileUploadModel,
    firebasePath: string,
    type: number,
    context: any,
  ) => {
    const fileReader = new FileReader();

    fileReader.onload = () => {
      context.onLoad(file, firebasePath, type);
    };

    fileReader.readAsDataURL(context.fileInformation);
  };

  export const convertListToNumericList = (genericList: any[]) => {
    let i = 1;

    for (let j = 0; j < genericList.length; j++) {
      genericList[j]['number'] = i;
      i++;
    }

    return genericList;
  };

  export const convertStringToDate = (value: string) => {
    var a = value.split(/[^0-9]/);
    return new Date(+a[0], +a[1] - 1, +a[2], +a[3], +a[4], +a[5]);
  };

  export const downloadFile = (reportFile: Blob, fileName: string) => {
    const dataType = reportFile.type;
    const binaryData = [];
    binaryData.push(reportFile);

    const filePath = window.URL.createObjectURL(
      new Blob(binaryData, {
        type: dataType,
      }),
    );

    const downloadLink = document.createElement('a');
    downloadLink.href = filePath;
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  export const removeItem = <T>(arr: Array<T>, value: T): Array<T> => {
    const index = arr.indexOf(value);

    if (index > -1) {
      arr.splice(index, 1);
    }

    return arr;
  };

  export const serialize = (data: any) => {
    return JSON.stringify(data);
  };

  export const deserialize = (data: string) => {
    return JSON.parse(data);
  };

  export const saveSessionItem = (key: string, value: string) =>
    sessionStorage.setItem(key, value);
  export const getSessionItem = (key: string) => sessionStorage.getItem(key);

  export const removeSessionItem = (key: string) =>
    sessionStorage.removeItem(key);

  export const clearSession = () => sessionStorage.clear();

  export const saveStorageItem = (key: string, value: string) =>
    localStorage.setItem(key, value);

  export const getStorageItem = (key: string) => localStorage.getItem(key);

  export const removeStorageItem = (key: string) =>
    localStorage.removeItem(key);

  export const getQualificationColor = (
    value: number,
    gradeEquivalences: GradeEquivalence[],
  ): string => {
    if (
      value >= gradeEquivalences[0].lowerGrade &&
      value <= gradeEquivalences[0].upperGrade
    ) {
      return 'red';
    } else if (
      value > gradeEquivalences[1].lowerGrade &&
      value <= gradeEquivalences[1].upperGrade
    ) {
      return 'orange';
    } else if (
      value > gradeEquivalences[2].lowerGrade &&
      value <= gradeEquivalences[2].upperGrade
    ) {
      return 'blue';
    } else if (
      value > gradeEquivalences[3].lowerGrade &&
      value <= gradeEquivalences[3].upperGrade
    ) {
      return 'green';
    } else {
      return 'black';
    }
  };

  export const gradeNumberIsValid = (
    value: number,
    gradeEquivalences: GradeEquivalence[],
  ): boolean =>
    value > gradeEquivalences[3].upperGrade ||
    value < gradeEquivalences[0].lowerGrade;

  export const getAverage = (score: number, count: number): number =>
    Math.round((score / count + Number.EPSILON) * 100) / 100;

  export const clearStorage = () => {
    const ip = localStorage.getItem(Enums.StorageVariables.IP);
    const browser = localStorage.getItem(Enums.StorageVariables.BROWSER);

    localStorage.clear();

    localStorage.setItem(Enums.StorageVariables.IP, ip);
    localStorage.setItem(Enums.StorageVariables.BROWSER, browser);
  };

  export const encrypt = (data: string): string =>
    CryptoJS.AES.encrypt(data, environment.key).toString();

  export const encryptObject = (data: any): string =>
    CryptoJS.AES.encrypt(JSON.stringify(data), environment.key).toString();

  export const decrypt = (cipherText: string): string => {
    const bytes = CryptoJS.AES.decrypt(cipherText, environment.key);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  export const decryptObject = (cipherText: string): any => {
    const bytes = CryptoJS.AES.decrypt(cipherText, environment.key);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  export const removeAccents = (text: string): string => {
    const accentMap: {
      [key: string]: string;
    } = {
      á: 'a',
      é: 'e',
      í: 'i',
      ó: 'o',
      ú: 'u',
      à: 'a',
      è: 'e',
      ì: 'i',
      ò: 'o',
      ù: 'u',
      ä: 'a',
      ë: 'e',
      ï: 'i',
      ö: 'o',
      ü: 'u',
      â: 'a',
      ê: 'e',
      î: 'i',
      ô: 'o',
      û: 'u',
      Á: 'A',
      É: 'E',
      Í: 'I',
      Ó: 'O',
      Ú: 'U',
      À: 'A',
      È: 'E',
      Ì: 'I',
      Ò: 'O',
      Ù: 'U',
      Ä: 'A',
      Ë: 'E',
      Ï: 'I',
      Ö: 'O',
      Ü: 'U',
      Â: 'A',
      Ê: 'E',
      Î: 'I',
      Ô: 'O',
      Û: 'U',
    };

    return text.replace(
      /[áéíóúàèìòùäëïöüâêîôûÁÉÍÓÚÀÈÌÒÙÄËÏÖÜÂÊÎÔÛ]/g,
      function (match) {
        return accentMap[match];
      },
    );
  };

  export const addToConsole = (
    message: string,
    data: any = undefined,
    isError: boolean = false,
  ): void => {
    if (!environment.production) {
      if (isError) {
        if (data !== undefined) {
          console.error(message, data);
        } else {
          console.error(message);
        }
        return;
      }

      if (data !== undefined) {
        console.log(message, data);
      } else {
        console.log(message);
      }
    }
  };
}
