import { Enums } from 'src/app/helper/enums';
import { Message } from 'src/app/helper/message';
import { Constant } from 'src/app/helper/constant';
import { CustomFunction } from 'src/app/helper/custom-function';
import { User } from 'src/app/model/authentication/user';
import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Role } from 'src/app/model/master-data/master-data';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterDataService } from 'src/app/service/master-data.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Component({
  selector: 'exphadis-user-update',
  templateUrl: './update.component.html',
})
export class UpdateLoginComponent implements OnInit {
  form: FormGroup;
  user: User;
  roleOptions: Role[];

  changeUsername: false;
  changeActive: false;
  shouldCreate: false;

  constructor(
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    private masterDataService: MasterDataService,
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<UpdateLoginComponent>,
  ) {
    this.user = defaults.user;
    this.changeUsername = defaults.changeUsername;
    this.changeActive = defaults.changeActive;
    this.shouldCreate = defaults.shouldCreate;
  }

  ngOnInit(): void {
    this.getRoles();
    this.initialiceForm();
  }

  initialiceForm() {
    this.form = this.fb.group({
      completeName: [this.defaults.completeName],
      username: [this.user.username, [Validators.required]],
      roles: [this.user.roles, [Validators.required]],
      password: [null],
      active: [this.user.active, [Validators.required]],
    });
  }

  onSubmit() {
    if (this.form.status === Enums.FormStatus.INVALID) {
      this.snackbar.open(
        Message.MustFulfillNecessaryInformationForTheUpdate,
        null,
        {
          duration: Constant.DefaultMessageDuration,
        },
      );

      return;
    }

    const user = new User();
    user.id = this.user.id;
    user.idPerson = this.user.idPerson;
    user.username = this.form.controls.username.value;

    if (this.form.controls.password.value) {
      user.password = this.form.controls.password.value;
    }

    user.active = this.form.controls.active.value;
    user.roles = this.form.controls.roles.value;

    if (this.shouldCreate) {
      user.userRegister = this.authenticationService.getUsername();
      user.timeRegister = CustomFunction.getCurrentTimeStamp();
      user.userUpdated = null;
      user.timeUpdated = null;

      this.addUser(user);
    } else {
      user.userRegister = this.user.userRegister;
      user.timeRegister = this.user.timeRegister;
      user.userUpdated = this.authenticationService.getUsername();
      user.timeUpdated = CustomFunction.getCurrentTimeStamp();

      this.updateService(user);
    }
  }

  private addUser(information: User) {
    this.authenticationService.addUser(information).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
    });
  }

  private updateService(information: User) {
    this.authenticationService.updateUser(information).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
    });
  }

  private getRoles() {
    this.masterDataService.getRoles().subscribe({
      next: (data: Role[]) => {
        this.roleOptions = data;
      },
    });
  }

  textOnly = (event: KeyboardEvent) => CustomFunction.textOnly(event.key);
}
