<h2 mat-dialog-title>Actualizar Información del Usuario</h2>

<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="person">
      <div fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field fxFlex>
          <mat-label>Nombres</mat-label>
          <input
            type="text"
            formControlName="completeName"
            [readonly]="true"
            matInput
          />
        </mat-form-field>
      </div>

      <div *ngIf="changeUsername" fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field fxFlex>
          <mat-label>Usuario</mat-label>
          <input
            onDrag="return false"
            onDrop="return false"
            autocomplete="off"
            (keypress)="textOnly($event)"
            type="text"
            formControlName="username"
            required
            matInput
          />
          <mat-hint>Indicar el nombre de usuario</mat-hint>
          <mat-error *ngIf="form.get('username').hasError('required')"
            >Es necesario indicar el nombre de usuario
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field fxFlex>
          <mat-label>Roles</mat-label>
          <mat-select formControlName="roles" multiple required>
            <mat-option
              *ngFor="let option of roleOptions"
              [value]="option.id"
              >{{ option.name }}</mat-option
            >
          </mat-select>
          <mat-error *ngIf="form.controls.roles.hasError('required')"
            >Indica las posiciones
          </mat-error>
        </mat-form-field>
      </div>

      <div *ngIf="!changeUsername" fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field fxFlex>
          <mat-label>Usuario</mat-label>
          <input
            onDrag="return false"
            onDrop="return false"
            [readonly]="true"
            autocomplete="off"
            (keypress)="textOnly($event)"
            type="text"
            formControlName="username"
            required
            matInput
          />
          <mat-hint>Indicar el nombre de usuario</mat-hint>
          <mat-error *ngIf="form.get('username').hasError('required')"
            >Es necesario indicar el nombre de usuario
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="column" fxLayout.gt-sm="row">
        <mat-form-field fxFlex>
          <mat-label>Password</mat-label>
          <input
            onDrag="return false"
            onDrop="return false"
            autocomplete="off"
            type="text"
            formControlName="password"
            matInput
          />
          <mat-hint>Indicar una contraseña</mat-hint>
          <mat-error *ngIf="form.get('password').hasError('required')"
            >Es necesario indicar una contraseña
          </mat-error>
        </mat-form-field>
      </div>

      <div
        *ngIf="changeActive"
        fxLayoutAlign="center center"
        fxLayout="column"
        fxLayout.gt-sm="row"
      >
        <mat-checkbox formControlName="active">Activo</mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions fxLayout="row" fxLayoutAlign="center">
    <button
      *ngIf="!shouldCreate"
      [disabled]="form.pristine"
      mat-raised-button
      color="primary"
    >
      Actualizar
    </button>
    <button
      *ngIf="shouldCreate"
      [disabled]="form.pristine"
      mat-raised-button
      color="primary"
    >
      Registrar
    </button>
  </mat-dialog-actions>
</form>
