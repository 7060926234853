import * as Sentry from '@sentry/browser';
import type { CaptureContext } from '@sentry/types';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomFunction } from 'src/app/helper/custom-function';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  private recentErrors: Set<string> = new Set();
  private errorCacheTime: number = +`${environment.errorCacheTime * 1000}`;

  constructor(private authenticationService: AuthenticationService) {}

  handleError(error: any): void {
    if (environment.production) {
      const errorString = this.stringifyError(error);

      if (this.recentErrors.has(errorString)) {
        return;
      }

      this.recentErrors.add(errorString);
      this.cleanupCache();

      this.sendToSentry(error);
    }

    CustomFunction.addToConsole('handleError:', error, true);
  }

  private stringifyError(error: any): string {
    if (error instanceof Error) {
      return `${error.name}: ${error.message}\n${error.stack}`;
    }
    return JSON.stringify(error);
  }

  private cleanupCache(): void {
    setTimeout(() => {
      this.recentErrors.clear();
    }, this.errorCacheTime);
  }

  private sendToSentry(error: any) {
    const context: CaptureContext = {
      level: 'error',
      user: {
        ip_address: this.authenticationService.getIp(),
        username: this.authenticationService.getUsername(),
        email: this.authenticationService.getCompleteName(),
      },
      tags: {
        application_name: 'Exphadis Intranet',
        session_token: this.authenticationService.getToken(),
      },
    };

    Sentry.configureScope((scope) => {
      scope.setUser({
        username: this.authenticationService.getUsername(),
        email: this.authenticationService.getCompleteName(),
      });
    });

    Sentry.captureException(error.originalError || error, context);
  }
}
