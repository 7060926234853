import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Enums } from 'src/app/helper/enums';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/model/authentication/user';
import { Login } from 'src/app/model/authentication/login';
import { environment } from 'src/environments/environment';
import { CustomFunction } from 'src/app/helper/custom-function';
import { Permission } from 'src/app/model/master-data/master-data';
import { SidenavService } from 'src/app/layout/sidenav/sidenav.service';
import { LoginInformation } from 'src/app/model/authentication/login-Information';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private router: Router,
    protected httpClient: HttpClient,
    private cookieService: CookieService,
    private sidenavService: SidenavService,
  ) {}

  login = (information: LoginInformation) =>
    this.httpClient
      .post<Login>(
        `${environment.backend}/${Enums.ApiPath.SECURITY}/login`,
        CustomFunction.serialize(information),
      )
      .pipe(
        map((data: Login) => {
          if (data) {
            this.clearLoginInformation();

            let date: Date = new Date(data.expires);

            const options = {
              secure: true,
              expires: date,
            };

            this.cookieService.set(
              Enums.CookieVariables.AUTHORIZATION,
              CustomFunction.encrypt(data.token),
              options,
            );
            this.cookieService.set(
              Enums.CookieVariables.COMPLETE_NAME,
              CustomFunction.encrypt(data.completeName),
              options,
            );

            this.setLoginCookies(data.username, information.password, date);

            CustomFunction.saveStorageItem(
              Enums.StorageVariables.EXPIRES,
              date.toISOString(),
            );
            CustomFunction.saveStorageItem(
              Enums.StorageVariables.CURRENT_USER,
              CustomFunction.serialize(data),
            );

            delete data.token;
            delete data.expires;

            const sidenavInformation = this.getPermissionList(
              data.access.permissions,
            );

            this.sidenavService.items = sidenavInformation;

            CustomFunction.saveStorageItem(
              Enums.StorageVariables.SIDENAV_INFORMATION,
              CustomFunction.serialize(sidenavInformation),
            );

            this.router.navigate([Enums.SitePath.DASHBOARD]).then();
          }
        }),
      );

  setLoginCookies(username: string, password: string, date: Date): void {
    const options = {
      secure: true,
      expires: date,
    };

    this.cookieService.set(
      Enums.CookieVariables.USERNAME,
      CustomFunction.encrypt(username),
      options,
    );
    this.cookieService.set(
      Enums.CookieVariables.PASSWORD,
      CustomFunction.encrypt(password),
      options,
    );
  }

  clearLoginCookies(): void {
    this.cookieService.delete(Enums.CookieVariables.USERNAME);
    this.cookieService.delete(Enums.CookieVariables.PASSWORD);
  }

  logout = (token: string) =>
    this.httpClient
      .post<boolean>(
        `${environment.backend}/${Enums.ApiPath.SECURITY}/logout/${token}`,
        null,
      )
      .pipe(
        map((data: boolean) => {
          if (data) {
            this.clearLoginInformation();
            this.router.navigate([Enums.SitePath.DEFAULT]).then();
          }
        }),
      );

  private clearLoginInformation(): void {
    CustomFunction.clearSession();
    CustomFunction.clearStorage();
    this.cookieService.deleteAll();

    this.sidenavService.items = [];
    this.sidenavService.addItems(CustomFunction.routesDefined());
  }

  getUsername = () =>
    CustomFunction.decrypt(
      this.cookieService.get(Enums.CookieVariables.USERNAME),
    );

  getPassword = () =>
    CustomFunction.decrypt(
      this.cookieService.get(Enums.CookieVariables.PASSWORD),
    );

  getCompleteName = () =>
    CustomFunction.decrypt(
      this.cookieService.get(Enums.CookieVariables.COMPLETE_NAME),
    );

  getToken = () =>
    CustomFunction.decrypt(
      this.cookieService.get(Enums.CookieVariables.AUTHORIZATION),
    );

  tokenIsValid = (): boolean =>
    this.cookieService.check(Enums.CookieVariables.AUTHORIZATION);

  getIp = () => CustomFunction.getStorageItem(Enums.StorageVariables.IP);

  getBrowser = () =>
    CustomFunction.getStorageItem(Enums.StorageVariables.BROWSER);

  getUserInformation = (): Login =>
    CustomFunction.deserialize(
      CustomFunction.getStorageItem(Enums.StorageVariables.CURRENT_USER),
    );

  getRoles(): number[] {
    const information: Login = CustomFunction.deserialize(
      CustomFunction.getStorageItem(Enums.StorageVariables.CURRENT_USER),
    );

    const roles: number[] = [];

    information.access.roles.forEach((role) => {
      roles.push(role.id);
    });

    return roles;
  }

  getUsers = (): Observable<User[]> =>
    this.httpClient.get<User[]>(`${environment.backend}/${Enums.ApiPath.USER}`);

  addUser = (user: User): Observable<boolean> =>
    this.httpClient.post<boolean>(
      `${environment.backend}/${Enums.ApiPath.USER}`,
      CustomFunction.serialize(user),
    );

  updateUser(user: User): Observable<boolean> {
    return this.httpClient.put<boolean>(
      `${environment.backend}/${Enums.ApiPath.USER}`,
      CustomFunction.serialize(user),
    );
  }

  deleteUser(id: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(
      `${environment.backend}/${Enums.ApiPath.USER}/${id}`,
    );
  }

  private getPermissionList(permissions: Permission[]) {
    const items = [];
    let object: any;

    for (const permission of permissions) {
      if (permission.active) {
        for (const item of this.sidenavService.items) {
          object = {};

          if (item.name === permission.name) {
            if (item.type) {
              if (item.type === 'subheading') {
                object = {
                  name: item.name,
                  position: item.position,
                  type: item.type,
                  customClass: item.customClass,
                };

                items.push(object);

                continue;
              }
            }

            object = {
              name: item.name,
              icon: item.icon,
              position: item.position,
            };

            if (item.routeOrFunction) {
              object.routeOrFunction = item.routeOrFunction;
            }

            if (item.subItems) {
              object.subItems = [];

              for (const subItem of item.subItems) {
                for (const perm of permissions) {
                  if (perm.active) {
                    if (subItem.name === perm.name) {
                      object.subItems.push({
                        name: subItem.name,
                        routeOrFunction: subItem.routeOrFunction,
                        position: subItem.position,
                      });
                    }
                  }
                }
              }
            }

            items.push(object);
          }
        }
      }
    }

    return items;
  }
}
