<div class="config-panel">
  <fury-title class="heading">
    <mat-icon>settings</mat-icon>
    <span>Configuración</span>
  </fury-title>

  <div class="section">
    <h5 class="subheading">NAVEGACION</h5>

    <div class="section-content">
      <mat-checkbox
        (change)="sidenavCollapsedChange($event)"
        [checked]="sidenavCollapsed$ | async"
        >COLAPSADO
      </mat-checkbox>
    </div>
  </div>
</div>
