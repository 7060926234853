export const Constant = {
  defaultLocale: 'es',
  UserResponseAfirmative: 'Si',
  UserResponseNegative: 'No',
  DefaultMessageDuration: 5000,
  LargeMessageDuration: 10000,
  HttpUnauthorized: 401,
  HttpMethodNotAllowed: 405,
  HttpBadRequest: 400,
  HttpForbidden: 403,
  DefaultTableRows: 10,
  DefaultTablePage: 0,
  DocumentTypeDni: 1,
  DocumentTypeForeignCarnet: 2,
  DocumentTypeRuc: 3,
  // TODO: HOLA
  CurrentUser: 'currentUser',
  IdSession: 'idSession',
  Ip: 'Ip',
  TypeText: 'text',
  Empty: '',
  RegexText: /^[a-zA-ZñÑáÁéÉíÍóÓúÚ -]*$/,
  RegexNumber: /^[0-9]*$/,
  RegexDecimalNumber: /^[0-9]*\.?[0-9]{0,2}$/,
  RegexSlash: '^/*$',
  RegexValidateYear: /^[12][0-9]{3}$/,
  RegexValidateDni: /^[0-9][0-9]{7}$/,
  RegexValidateForeignCard: /^[0-9][0-9]{11}$/,
  RegexValidateRuc: /^[0-9][0-9]{12}$/,
  Dot: '.',
  DateSplitSymbol: '/',
  GenericImageWoman: 'assets/img/avatars/generic_woman.jpg',
  GenericImageMan: 'assets/img/avatars/generic_men.jpg',
  ManSexOption: 'Masculino',
  WomanSexOption: 'Femenino',
  MotherRelationshipType: 2,
  FatherRelationshipType: 1,
  ZeroValue: 0,
  FirebasePathStudentPayment: 'student-payment',
  FirebasePathStudentTicket: 'student-ticket',
  FirebasePathEnrollmentCompromise: 'enrollment-compromise',
  FirebasePathPsychologyAttachments: 'psychology-attachments',
  NullTextValue: 'null',
  NullValue: null,
  True: true,
  False: false,
  DefaultModalWidth: '600px',
  LargeModalWidth: '1200px',
  PictureFormatType: 'image/jpeg,image/png',
  TicketFormatType: 'application/pdf',
  PensionPaymentType: 1,
  InterestPaymentType: 3,
  EducativeMaterialPaymentType: 4,
  UniformPaymentType: 5,
  EnrollmentPaymentType: 6,
  BankMethodPaymentType: 4, // TODO: CAMBIARLO POR ENUMS
  OfficeMethodPaymentType: 2, // TODO: CAMBIARLO POR ENUMS
  Undefined: 'undefined',
  AllStatus: 4,
  MaxPercentage: 100,
  PictureUpload: 1,
  TicketUpload: 2,
  RejectedStatus: 3,
  ImageUpload: 1,
  FileUpload: 2,
  FoundBankPayment: 'Pago Bancario',
  OneChild: 1,

  DefaultValue: 1,
  DefaultPageSize: 15,
  DefaultDebounceTime: 1500,
  PageSizeOptions: [15, 30, 45],
  EventKeyUp: 'keyup',
  FirstBimester: 1,
  FirstUnity: 'Primera Unidad',
  SecondUnity: 'Segunda Unidad',
  SecondBimester: 2,
  ThirdUnity: 'Tercera Unidad',
  FourUnity: 'Cuarta Unidad',
  ThirdBimester: 3,
  FiveUnity: 'Quinta Unidad',
  SixUnity: 'Sexta Unidad',
  FourBimester: 4,
  SevenUnity: 'Septima Unidad',
  EightUnity: 'Octava Unidad',
  DefaultSliceDetail: 25,
  DotsForDetail: '...',
  Recomendation: 1,
  Observation: 2,

  Token: 'token',
  PaymentAdvance: 9,
  PeriodicityMonth: 3,
  PersonType: 1,

  TeacherPosition: 1,
  TutorshipPosition: 4,
  PsychologyPosition: 7,
  InformerPosition: 6,

  InformInitiateStage: 1,
  InformObtainStage: 4,
  SelectUnity: 'Seleccionar la unidad',
  SelectBimester: 'Seleccionar el bimestre',
  SelectPaymentToUpdate: 'Seleccionar el pago a editar',
  SelectFilterType: 'Tipo de filtro',
  FileUploadState: 'in',
  SelectYear: 'Seleccionar el año',
  PositionTeacher: 'Docente',
  PositionPsycology: 'Psicóloga',
  PositionCoordinator: 'Coordinadora',
};
