// tslint:disable-next-line: no-namespace
export namespace Enums {
  export enum BankType {
    Interbank = 1,
    BCP,
    Scotiabank,
    BBVA,
    YAPE,
  }

  export enum IncomeMoneyMovementType {
    Pensions = 1,
    Arrears = 3, // Moras
    EducativeMaterial = 4,
    Uniforms = 5,
    Enrollments = 6,
    DelayDiscounts = 7,
    FaultsDiscounts = 8,
    OtherIncomes = 10,
  }

  export enum CommitmentStatusType {
    Pending = 1,
    Complete = 2,
    Dismissed = 3,
  }

  export enum ProgressInformMassiveDownloadType {
    ExcelZip = 1,
    ExcelFiles = 2,
    PdfFiles = 3,
    PdfZip = 4,
  }

  export enum ProgressInformDownloadType {
    Excel = 1,
    Pdf = 2,
  }

  export enum MethodPaymentType {
    Office = 2,
    Bank = 4,
  }

  export enum OriginPayment {
    StudentModule = 1,
    AdminValidation,
  }

  export enum TypePaymentRegister {
    Normal = 1,
    ToComplete,
  }

  export enum Months {
    Enero = 'Enero',
    Febrero = 'Febrero',
    Marzo = 'Marzo',
    Abril = 'Abril',
    Mayo = 'Mayo',
    Junio = 'Junio',
    Julio = 'Julio',
    Agosto = 'Agosto',
    Setiembre = 'Setiembre',
    Octubre = 'Octubre',
    Noviembre = 'Noviembre',
    Diciembre = 'Diciembre',
  }

  export enum Role {
    ADMINISTRATOR = 1,
    BOSS = 2,
    COORDINATOR_TUTORSHIP = 3,
    SECRETARY_TREASURER = 4,
    TEACHER = 5,
    ATTORNEY = 6,
    STUDENT = 7,
    ANALYST = 8,
    PRACTICING = 9,
    REPORTS = 10,
    PSYCHOLOGIST = 11,
  }

  export enum Position {
    TEACHER = 1,
    TUTORSHIP = 4,
    PSYCHOLOGY = 7,
    INFORMER = 6,
  }

  export enum ConfigurationVariableType {
    Maintenance = 1,
    CleanLocalStorage,
    Test,
  }

  export enum CustomerInformStage {
    Started = 1,
    Inform,
    Followed,
    Obtained,
    Rejected,
    Lost,
  }

  export enum HttpStatus {
    UNAUTHORIZED = 401,
    METHOD_NOT_ALLOWED = 405,
    BAD_REQUEST = 400,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    INTERNAL_SERVER_ERROR = 500,
    NO_CONTENT = 204,
    TO_MANY_REQUEST = 429,
    EXPECTATION_FAILED = 417,
    CREATED = 201,
    OK = 200,
  }

  export enum HttpVerbs {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
  }

  export enum ApiPath {
    COURSE = 'api/course',
    ENROLLMENT = 'api/enrollment',
    INFORMATION = 'api/information',
    MAINTENANCE = 'api/maintenance',
    MASTER = 'api/data',
    NOTE = 'api/note',
    PAYMENT = 'api/payment',
    PERSON = 'api/person',
    PSYCHOLOGY = 'api/psychology',
    REPORT = 'api/report',
    SECURITY = 'api/security',
    STUDENT = 'api/student',
    TUTORSHIP = 'api/tutorship',
    USER = 'api/user',
    WORKER = 'api/worker',
    WORKSHOP = 'api/workshop',
    QUALIFICATION = 'api/qualification',
    HEALTH = 'health',
  }

  export enum StorageVariables {
    PAYMENT_VALIDATE_STATUS = 'payment-validate-status-types',
    SIDENAV_INFORMATION = 'sidenav-information',
    ENCRYPTED_PASSWORD = 'sendEncryptedPassword',
    ROLE_TYPE = 'role-types',
    CURRENT_USER = 'current-user',
    IP = 'ip',
    BROWSER = 'browser',
    EXPIRES = 'expires',
  }

  export enum CookieVariables {
    USERNAME = 'username',
    PASSWORD = 'password',
    COMPLETE_NAME = 'complete_name',
    AUTHORIZATION = 'authorization',
  }

  export enum ContentType {
    JSON = 'application/json',
    JSON_PROBLEM = 'application/problem+json',
    BLOB_AS_JSON = 'application/octet-stream',
  }

  export enum EnrollmentConsumer {
    STUDENT = 'student_enrollment',
    VIEWER = 'viewer_enrollment',
    MEDIA_MANAGEMENT = 'media_management_enrollment',
  }

  export enum SitePath {
    ALL = '**',
    APPOINTMENT = 'appointment',
    ASSIGNED_COMPETENCE = 'asigned/competence',
    ASSIGNED_ADD_COMPETENCE = 'asigned/add/competence',
    ASSISTANT_JUSTIFY = 'assistant/justify',
    ASSISTANT_LIST = 'assistant/list',
    BANK_REVISION = 'bank/revisions',
    BANK_REVISION_DETAIL = 'bank/revision/detail',
    COURSE = 'course',
    COMPETENCE = 'competence',
    COURSE_COMPETENCE_CRITERIA = 'course/competence/criteria',
    CONFIGURATION_AREA = 'configuration/area',
    CONFIGURATION_UNITY = 'configuration/unity',
    CONFIGURATION_PROMOTION = 'configuration/promotion',
    CONFIGURATION_PAYMENT = 'configuration/payment',
    CONFIGURATION_STUDENT_PAYMENT = 'configuration/student/payment',
    CONFIGURATION_COMPETENCE = 'configuration/competence',
    DEFAULT = '',
    DASHBOARD = 'dashboard',
    ENROLLMENT = 'enrollment',
    EVALUATION_DETAIL = 'evaluation-details',
    FORGOT_PASSWORD = 'forgot-password',
    FIND_OPERATION_NUMBER = 'find/operation/number',
    INFORM = 'informs',
    INFORM_STAGE = 'informs/stage',
    MAINTENANCE = 'maintenance',
    MEDIA_MANAGEMENT = 'media-management',
    NOT_FOUND = 'not-found',
    OBSERVATION_LIST = 'observation/list',
    OBSERVATION_REGISTER = 'observation/register',
    PAYMENT_PLAN = 'payment-plan',
    PAYMENT_LIST_STUDENT = 'payment/list/student',
    PERSONAL_ADVANCE = 'personal/advance',
    PERSONAL_DISCOUNT = 'personal/discount',
    PERSONAL_PAYMENT = 'personal/payment',
    PSYCHOLOGY = 'psychology',
    REPORT = 'reports',
    RECOMMENDATION = 'recomendations',
    RECOMMENDATION_STUDENT = 'recommendation/student',
    STUDENT_LIST = 'student/list',
    STUDENT_REGISTER = 'student/register',
    STUDENT_PAYMENT = 'payment/student-payment',
    STUDENT_PROMOTION = 'student/promotion',
    SUBJECT = 'asignature',
    STUDENT_LIST_PAYMENT = 'payment/list/student',
    STUDENT_GRADE = 'student/grades',
    STUDENT_ATTORNEY = 'student/attorneys',
    TUTORSHIP = 'tutorship',
    USER_MANAGEMENT = 'user/management',
    WORKER_LIST = 'worker/list',
    WORKER_REGISTER = 'worker/register',
    WORKSHOP = 'workshops',
    WORKSHOP_INSCRIPTION = 'workshop/inscriptions',
    WORKSHOP_FOR_STUDENT = 'workshop/for/student',
    WORKER_EVALUATION = 'worker-evaluations',
    WORKER_PAYMENT_PLAN = 'payment-plan',
    WORKER_ASSISTANCE = 'worker-assistance',
    WORKER_ASSISTANCE_DETAIL = 'worker-assistance-detail',
    PAYMENT_COMMITMENT = 'payment-commitment',
    QUALIFICATION_COGNITIVE = 'qualification-cognitive',
    QUALIFICATION_CONDUCT = 'qualification-conduct',
    QUALIFICATION_PROCEDURAL_AND_ATTITUDINAL = 'qualification-procedural-and-attitudinal',
    ANNOUNCEMENT = 'announcement',
  }

  export enum FirebasePath {
    STUDENT_PAYMENT = 'student-payment',
    ENROLLMENT = 'enrollment',
    STUDENT_TICKET = 'student-ticket',
    PSYCHOLOGY = 'psychology',
    CONTRACT = 'contract',
    ANNOUNCEMENTS = 'announcements',
  }

  export enum MediaManagementConsumer {
    ENROLLMENT_STUDENT = 'student_enrollment',
  }

  export enum MediaType {
    JPEG = 'image/jpeg',
    PNG = 'image/png',
    SVG = 'image/svg+xml',
  }

  export enum MediaTypeNumber {
    GIF = 1,
    JPEG = 2,
    PNG = 3,
    SVG = 4,
    PDF = 5,
    TXT = 6,
    MPEG = 7,
    WAV = 9,
    WEBM = 10,
    XLSX = 11,
    PPTX = 12,
    XML = 13,
    SQL = 14,
  }

  export enum FormStatus {
    VALID = 'VALID',
    INVALID = 'INVALID',
  }

  export enum PersonType {
    NATURAL = 1,
    LEGAL = 2,
  }

  export enum Contract {
    PAY_ROLL = 1,
    RECEIPT = 2,
    REMYPE = 3,
    MYPE = 4,
  }

  export enum ApportType {
    AFP = 1,
    ONP = 2,
  }

  export enum SidenavState {
    Fixed = 'fixed',
    Expanded = 'expanded',
    Collapsed = 'collapsed',
    CollapsedHover = 'collapsedHover',
    Mobile = 'mobile',
    MobileOpen = 'mobileOpen',
  }

  export enum TutorshipMessageType {
    Recommendation = 1,
    Observation = 2,
  }
}
