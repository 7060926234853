<div
  class="search-wrapper"
  fxLayout="row"
  [ngClass]="{ focus: focused, 'has-input': input }"
  fxFlex
  (furyClickOutside)="closeDropdown()"
>
  <mat-icon class="search-icon">search</mat-icon>
  <input
    type="search"
    autocomplete="off"
    spellcheck="false"
    class="search-input"
    [(ngModel)]="input"
    (focus)="openDropdown()"
    placeholder="Search..."
  />

  <div class="search-dropdown">
    <div class="content results" *ngIf="input" fxLayout="column">
      <div class="heading" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="icon">search</mat-icon>
        Search results for: {{ input }}
      </div>
      <div class="items">
        <div class="item" matRipple [routerLink]="['/forms/form-elements']">
          Form Elements
        </div>
        <div class="item" matRipple [routerLink]="['/forms/form-wizard']">
          Form Wizard
        </div>
        <div class="item" matRipple [routerLink]="['/editor']">
          WYSIWYG Editor
        </div>
        <div class="item" matRipple [routerLink]="['/apps/inbox']">Inbox</div>
        <div class="item" matRipple [routerLink]="['/components/dialogs']">
          Material Dialog
        </div>
      </div>
    </div>

    <div class="content recents" fxLayout="row" *ngIf="!input">
      <div class="recently" fxLayout="column" fxFlex>
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">restore</mat-icon>
          Recently Visited
        </div>
        <div class="items">
          <div
            class="item"
            matRipple
            *ngFor="let item of recentlyVisited"
            [routerLink]="item.routeOrFunction"
            (click)="closeDropdown()"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="frequently" fxLayout="column" fxFlex fxFlexOffset="16px">
        <div class="heading" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">youtube_searched_for</mat-icon>
          Frequently Visited
        </div>
        <div class="items">
          <div class="item" matRipple [routerLink]="['/forms/form-elements']">
            Form Elements
          </div>
          <div class="item" matRipple [routerLink]="['/forms/form-wizard']">
            Form Wizard
          </div>
          <div class="item" matRipple [routerLink]="['/editor']">
            WYSIWYG Editor
          </div>
          <div class="item" matRipple [routerLink]="['/apps/inbox']">Inbox</div>
          <div class="item" matRipple [routerLink]="['/components/dialogs']">
            Material Dialog
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
