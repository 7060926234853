<button
  class="button"
  fxHide
  fxShow.gt-sm
  mat-button
  (click)="toggleFullscreen()"
  fxFlex="grow"
>
  <mat-icon *ngIf="!isFullscreen">fullscreen</mat-icon>
  <mat-icon *ngIf="isFullscreen">fullscreen_exit</mat-icon>
</button>
