import { formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe implements PipeTransform {
  transform(
    value: Date | string | null,
    format: string = 'dd/MM/yyyy',
    locale: string = 'es-PE',
  ): string {
    if (!value) {
      return '';
    }
    return formatDate(value, format, locale);
  }
}
