import { Audit } from '../base/audit';
import { GenericOption } from '../base/generic-option';

export class User extends Audit {
  id: number;
  idPerson: number;
  username: string;
  password: string;
  active: boolean;
  completeName: string;
  roleNames: string;
  roles: number[];
  state: string;
  roleList: GenericOption[];
}
