import { Router } from '@angular/router';
import { Enums } from 'src/app/helper/enums';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { fadeInUpAnimation } from '../../../../@fury/animations/fade-in-up.animation';

@Component({
  selector: 'exphadis-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  animations: [fadeInUpAnimation],
})
export class ForgotComponent implements OnInit {
  form = this.fb.group({
    email: [null, Validators.required],
  });

  constructor(private router: Router, private fb: FormBuilder) {}

  ngOnInit() {}

  // #region EVENT BUTTONS

  public onSubmit() {
    this.router.navigate([Enums.SitePath.DEFAULT]);
  }

  // #region
}
