<div class="toolbar" fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="row" fxLayoutAlign="start center">
    <fury-toolbar-sidenav-mobile-toggle
      (openSidenav)="openSidenav.emit()"
    ></fury-toolbar-sidenav-mobile-toggle>
  </div>

  <div fxLayout="row">
    <fury-toolbar-fullscreen-toggle
      class="toolbar-button"
    ></fury-toolbar-fullscreen-toggle>
    <fury-toolbar-search
      fxHide
      fxShow.gt-sm
      fxHide.gt-md
      class="toolbar-button"
    ></fury-toolbar-search>
    <fury-toolbar-notifications
      class="toolbar-button"
    ></fury-toolbar-notifications>
    <fury-toolbar-user class="toolbar-button"></fury-toolbar-user>
  </div>
</div>
